
export default {
  methods: {
    progressColor() {
      if (this.$store.state.successQueue.is_waiting) {
        return 'black';
      }

      if (this.$store.state.successQueue.is_reserved) {
        return 'lime'
      }

      return 'grey lighten-1';
    },
    stop() {
      const state = this.$store.state.successQueue
      this.$store.commit('successQueue/stop')
      this.$nuxt.$emit('queueDone', state)
    },
    open() {
      const state = this.$store.state.successQueue
      this.$store.commit('successQueue/stop')
      this.$nuxt.$emit('queueDone', state)
      this.$router.push(this.$store.state.successQueue.route)
    },
    async savePin() {
      await this.$store.dispatch('me/pin', {id:this.$store.state.successQueue.entityId, type:this.$store.state.successQueue.entityType})
      this.$store.dispatch('snackbar/success', this.$vuetify.lang.t('$vuetify.layouts.pinadded'));
    }
  }
}
