export const state = () => ({
    drawer: null,
    poolSidebar: true,
    storageVersion: 1,
  });
  
  export const getters = {
  }
  
  export const mutations = {
    drawer(state, value) {
      state.drawer = value
    },
  
    toggleDrawer(state) {
      state.drawer = !state.drawer
    },
    togglePoolSidebar(state) {
      state.poolSidebar = !state.poolSidebar
    }
  }
  
  export const actions = {
  }
  