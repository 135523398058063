
import ModalToolbar from "../ModalToolbar.vue"

export default {
  components: { ModalToolbar },
  props: {
    text: String,
    title: String,
    float: String,
    small: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: 'icon icon-help'
    }
  },
  data:() => ({
    hint: false
  })
}
