export default async function(context) {
  if (context.store.state.authStorage.challenge) {
    await context.store.dispatch('authStorage/ensureChallenge')
    if (context.store.state.authStorage.isAuthenticated) {
      return;
    }
  }

  return context.redirect({
    name: 'auth-login'
  })
}
