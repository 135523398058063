

import ModalToolbar from '../ModalToolbar.vue'
export default {
  components: { ModalToolbar },
  data:() => ({
    search: '',
    dialog: false,
    showContentIntegrations: false,
  }),
  computed: {
    filteredContentButtons() {
      return this.$store.state.me.buttons.content.filter(button => {
        return this.search ? button.title.toLowerCase().includes(this.search.toLowerCase()) : true
      })
    },
    filteredContentIntegrationButtons() {
      return this.$store.state.me.buttons.content_integrations.filter(button => {
        return this.search ? button.title.toLowerCase().includes(this.search.toLowerCase()) : true
      })
    },
    filteredAdminButtons() {
      return this.$store.state.me.buttons.admin.filter(button => {
        return this.search ? button.title.toLowerCase().includes(this.search.toLowerCase()) : true
      })
    },
    filteredSettingsButtons() {
      return this.$store.state.me.buttons.settings.filter(button => {
        return this.search ? button.title.toLowerCase().includes(this.search.toLowerCase()) : true
      })
    },
  }
}
