export const state = () => ({
  title: null,
  closeOnly: false,
  noModal: false,
  id: null,
  is_done: false, // indicates the success icon on the modal, but has nothing to do with the process done or not
  is_reserved: false,
  is_waiting: false,
  is_error: false,
  route: {},
  entityId: false,
  entityType: false,
  active: false, // indicates if the modal is visible and "ative"
  count: 0,
  activeQueueOrNotFinishedQueueProcess: false,
  activeTimeout: null
});

export const mutations = {
  start(context, payload) {
    const noModal = payload.noModal || false
    const isModalActive = noModal ? false : true
    context.id = payload.id
    context.title = payload.title || ''
    context.buttonlabel = payload.buttonlabel || ''
    context.route = payload.route || ''
    context.entityId = payload.entityId || ''
    context.entityType = payload.entityType || ''
    context.closeOnly = payload.closeOnly || false
    context.noModal = noModal
    context.active = isModalActive
    context.is_error = false
    context.count = 0
    context.activeQueueOrNotFinishedQueueProcess = true
  },
  update(context, payload) {
    context.is_done = payload.is_done
    context.is_reserved = payload.is_reserved
    context.is_waiting = payload.is_waiting
    context.is_error = payload.is_error
    context.count = payload.count
    if (payload.is_done || payload.is_error) {
      // its finished, either by done or by error
      context.activeQueueOrNotFinishedQueueProcess = false;
    }
  },
  stop(context) {
    context.active = false
    context.is_done = false
    context.is_error = false
    context.activeQueueOrNotFinishedQueueProcess = false;
    context.count = 0
  },
  onStatusQueueFinish(context) {
    if (context.noModal) {
      context.active = false
      context.is_done = false
      context.is_error = false
      context.activeQueueOrNotFinishedQueueProcess = false;
      context.count = 0
    }
  },
  setActiveTimeout(context, timeout) {
    context.activeTimeout = timeout
  },
  clearActiveTimeout(context) {
    clearTimeout(context.activeTimeout)
    context.activeTimeout = undefined;
  }
}

export const getters = {
  hasRunningProcess(state) {
    return state.activeQueueOrNotFinishedQueueProcess
  },
  statusBarMessage(state) {
    return state.title
  }
}

export const actions = {
  clear (context) {
    context.commit('stop')
  },
  startNoModal (context, payLoad) {
    context.commit('clearActiveTimeout')
    context.commit('start', {
      id: payLoad.id,
      noModal: true,
      title: payLoad.title,
    })
    context.dispatch('run', payLoad.id)
  },
  start (context, payLoad) {
    context.commit('clearActiveTimeout')
    let object = {
      title: payLoad.title,
      id: payLoad.id,
      route: payLoad.route,
      buttonlabel: payLoad.hasOwnProperty('buttonlabel') ? payLoad.buttonlabel : false,
      entityId: payLoad.hasOwnProperty('entityId') ? payLoad.entityId : false,
      entityType: payLoad.hasOwnProperty('entityType') ? payLoad.entityType : false,
      closeOnly: payLoad.hasOwnProperty('closeOnly') ? payLoad.closeOnly : false
    }
    context.commit('start', object)
    context.dispatch('run', payLoad.id)
  },
  run (context) {
    const timeout = setTimeout(() => {
      context.dispatch('poll')
    }, context.state.activeTimeout ? 2000 : 750)
    context.commit('setActiveTimeout', timeout)
  },
  poll(context) {
    this.$axios.get('queue/status/' + context.state.id, {progress: false}).then(response => {

      if (context.state.count >= 30) {
        // force error after 30 trys
        return context.commit('update', {
          is_done: true,
          is_reserved: false,
          is_waiting: false,
          is_error: true,
          count: context.state.count + 1
        })
      }
      context.commit('update', {
        is_done: response.data.is_done,
        is_reserved: response.data.is_reserved,
        is_waiting: response.data.is_waiting,
        is_error: false,
        count: context.state.count + 1
      })

      if (!response.data.is_done) {
        context.dispatch('run')
      }

    }).catch(error => {
      context.commit('update', {
        is_done: true,
        is_reserved: false,
        is_waiting: false,
        is_error: true,
        count: context.state.count + 1
      })
    })
  }
}
