
import ModalToolbar from './ModalToolbar.vue';
export default {
  components: { ModalToolbar },
  props: {
    value: [String, Number],
    rows: [String, Number],
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    qrcode: {
      type: Boolean,
      default: false,
    },
    qrcodeBase64: {
      type: [String, Boolean],
      default: ''
    }
  },
  data:() => ({
    menu: false,
  }),
  methods: {
    copyText () {
      var text = this.value
      navigator.clipboard.writeText(text).then(() => {
        this.$store.dispatch('snackbar/success', this.$vuetify.lang.t('$vuetify.copyToClipboardSucces'));
      })
    }
  }
}
