import { showReportDialog } from '@sentry/vue'
export default function() {
  return {
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    release: process.env.SENTRY_RELEASE,
    // Add native Sentry config here
    // https://docs.sentry.io/platforms/javascript/guides/vue/configuration/options/
    environment: process.env.NODE_ENV,
    beforeSend (event) {
      if (event.exception) {
        const user = window?.$nuxt?.$auth?.user
          // native Sentry JS Bug Reporter
          // https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/user-feedback/
          // eslint-disable-next-line no-undef
          showReportDialog({
            eventId: event.event_id,
            title: 'Es gibt scheinbar Probleme auf unserer Seite.',
            subtitle:	'Unser Team wurde benachrichtigt. Wenn du uns helfen möchtest, teile uns bitte unten mit, was geschehen ist.',
            subtitle2: 'Was ist genau passiert?',
            labelComments: 'Beschreibe, welche Aktion du ausführen wolltest oder auf welchen Button du geklickt hast:',
            user: {
              email: user && user.hasOwnProperty('email') ? user.email : '',
              name: user ? `${user.firstname} ${user.lastname}` : ''
            }
          })
      }
      return event
    },
  }
}
