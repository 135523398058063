/*
created() {
  this.$nuxt.$on('queueDone', () => {
    this.$vuetify.goTo(0)
    this.fetchData()
  })
},
beforeDestroy() {
  this.$nuxt.$off('queueDone')
}
*/
export const state = () => ({
  jobs: [],
  processingJobs: [],
  currentJob: false,
  useModalView: false,
});

export const getters = {
  modalState(state) {
    return state.useModalView && state.jobs.length > 0
  },
  allDone(state) {
    let allDone = true;
    state.jobs.forEach(item => {
      if (!item.is_done) {
        allDone = false
      }
    })

    return allDone
  }
}

export const mutations = {
  add(context, state) {
    state.is_error = false
    context.processingJobs.push(state)
    context.jobs.push(state)
  },
  update(context, object) {
    const index = object.index;
    context.jobs[index].attempts = context.jobs[index].attempts + 1
    context.jobs[index].is_done = object.is_done
    context.jobs[index].is_reserved = object.is_reserved
    context.jobs[index].is_waiting = object.is_waiting
    context.jobs[index].is_error = object.is_error
    context.currentJob = index
  },
  clear(context) {
    context.jobs = []
    context.currentJob = false
  },
  cleanupCurrentJob(context) {
    context.currentJob = false
  },
  removeProcessingJob (context, index) {
    context.processingJobs.splice(index, 1)
  },
  changeModalView(context, value) {
    context.useModalView = value
  }
}

export const actions = {
  startNoModal(context, payLoad) {
    context.commit('changeModalView', false)
    context.dispatch('startInternal', payLoad)
  },
  start(context, payLoad) {
    context.commit('changeModalView', true)
    context.dispatch('startInternal', payLoad)
  },
  startInternal (context, payLoad) {
    let object = {
      title: payLoad.title,
      attempts: 0,
      id: payLoad.id,
      queue: payLoad.hasOwnProperty('queue') ? payLoad.queue : 'queue',
      timeout: payLoad.hasOwnProperty('timeout') ? payLoad.timeout : 2000,
      route: false,
      is_done: false,
      is_reserved: false,
      is_waiting: false,
      is_error: false,
    }
    context.commit('add', object)
    context.dispatch('run', payLoad)
  },
  run (context, job) {
    let jobId = job.id
    let timeout = job.timeout

    setTimeout(() => {
      context.dispatch('poll', jobId)
    }, timeout)
  },
  poll(context, jobId) {

    const index = context.state.jobs.findIndex(item => item.id == jobId)
    if (index >= 0) {

      const jobObject = context.state.jobs[index]
      const currentJobContext = context.state.currentJob

      // there is currently no job in queue stack, use the current id
      if (currentJobContext === false || currentJobContext == index) {

        this.$axios.get('queue/status/' + jobId + '?queue=' + jobObject.queue, {progress: false}).then(response => {

          if (jobObject.attempts >= 30) {
            context.commit('update', {
              index: index,
              is_done: true,
              is_reserved: response.data.is_reserved,
              is_waiting: response.data.is_waiting,
              is_error: true
            })
            context.commit('cleanupCurrentJob')
            context.commit('removeProcessingJob', index)
            return
          }

          context.commit('update', {
            index: index,
            is_done: response.data.is_done,
            is_reserved: response.data.is_reserved,
            is_waiting: response.data.is_waiting,
            is_error: false
          })

          if (!response.data.is_done) {
            context.dispatch('run', jobObject)
          } else {
            context.commit('cleanupCurrentJob')
            context.commit('removeProcessingJob', index)
          }
        })
      } else {
        // lets wait this queue job for until a slot is free
        context.dispatch('run', jobObject)
      }
    }
  }
}
