
import Terms from '../views/Terms.vue'
export default {
  components: { Terms },
  data:() => ({
    terms: false,
    ds: false
  }),
  methods: {
    async accept() {
      await this.$axios.put('me/settings', {has_terms_accepted: this.$store.state.me.current_terms_version})
      await this.$store.dispatch('me/fetch')
    }
  }
}
