
import CopyToClipBoard from './CopyToClipBoard.vue'
import ModalToolbar from './ModalToolbar.vue'
export default {
  components: { ModalToolbar, CopyToClipBoard},
  props: {
    task: {
      type: String,
      default: 'generic'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data:() => ({
    types: [
      {type: 'generic', label: 'Freie Text Eingabe'},
      {type: 'seo-teaser', label: 'SEO optimierter Teaser aus Text erstellen'},
      {type: 'some-text', label: 'Für Social Media optimieren'},
      {type: 'typo', label: 'Rechtschreibfehler korrigieren'},
      {type: 'keyword-text', label: 'Text aus Keywords erstellen'},
    ],
    type: 'generic',
    dialog: false,
    promptValue: '',
    answers: [],
    isLoading: false
  }),
  watch: {
    value(value) {
      this.promptValue = value
    }
  },
  mounted() {
    this.promptValue = this.value
  },
  methods: {
    async send() {
      this.isLoading = true
      try {
        const response = await this.$axios.post('open-ai', {prompt: this.promptValue, type: this.type.type})
        this.answers.unshift({output: response.data, type: this.type, date: Date.now()})
      } catch (e) {
        console.error(e)
      }
      this.isLoading = false
    }
  }
}
