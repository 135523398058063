
export default {
  data:() => ({
    items: [
      {
        color: 'pink lighten-1',
        src: 'tutorial_0.png',
        title: 'Alles bereit - Jetzt geht\'s los',
        text: `
        Wenn du Flyo öffnest, findest du links das Hauptmenü. Hier hast du stets Zugriff auf alle wichtigen Funktionen von Flyo: Inhalte, Content Pools, Integrationen, Einstellungen und mehr. Klicke auf deinen Namen, um persönlichen Einstellungen wie Passwort, 2-Faktoren-Authentifizierung, etc. aufzurufen.
        `,
      },
      {
        color: 'orange darken-4',
        src: 'tutorial_5.png',
        title: 'Organisiere deine Inhalte',
        text: `
        Mithilfe von Content Pools organisierst du alle in Flyo verwalteten Inhalte. Content Pools können entweder mit UND/ODER-Regeln automatisiert oder kuratiert von Hand mit Inhalten gefüllt werden. Neue Content Pools erstellt du via grünem Knopf mit Plus-Symbol unten rechts in Flyo.
        `,
      },
      {
        color: 'purple darken-4',
        src: 'tutorial_6.png',
        title: 'Verbinde deine Kanäle',
        text: `
        Mit Integrationen verbindest du deine Inhalte aus Flyo mit deinem Ökosystem: Webseiten, Newsletter, Digital Signage oder Shopify können mit wenigen Kllicks und vorgefertigten Apps verbunden werden. Für volle Flexibilität stehen dir frei konfigurierbare API- und SDK-Tools zur Verfügung.
        `,
      },
      {
        color: 'light-green darken-1',
        src: 'tutorial_1.png',
        title: 'Benachrichtigungen & Neue Inhalte hinzufügen',
        text: `
        Auf der rechten Seite der Flyo Oberfläche findest du oben deine Benachrichtigungen, kannst den Arbeitsverlauf aufrufen oder die Hilfefunktion nutzen. Unten rechts findest du stets einen grünen Knopf mit einem Plus-Symbol. Über diesen Knopf kannst du neue Inhalte hinzufügen, Content Pools erstellen, Teammitglieder einladen - kurz: Immer wenn du etwas Neues erstellen möchtest, ist dieser Knopf die richtige Anlaufstelle.
        `,
      },
    ],
  }),
  methods: {
    async hide() {
      await this.$axios.put('me/settings', {show_intro_overlay: 0})
      await this.$store.dispatch('me/fetch')
    }
  }
}
