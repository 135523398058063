
export default {
  methods: {
    progressColor(job) {
      if (job.is_waiting) {
        return 'black';
      }

      if (job.is_reserved) {
        return 'lime'
      }

      return 'grey lighten-1';
    },
    open(job) {
      this.$store.commit('queue/clear')
      this.$router.push(job.route)
    },
    clearAndDone() {
      this.$nuxt.$emit('queueDone')
      this.$store.commit('queue/clear')
    }
  }
}
