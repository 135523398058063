import moment from 'moment'

import 'moment/locale/de'

import 'moment-timezone'

moment.locale('de')

moment.tz.setDefault('Europe/Zurich')

export default (ctx, inject) => {
  ctx.$moment = moment
  inject('moment', moment)
}
