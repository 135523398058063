export const state = () => ({
  challenge: false,
  activeOrganisationId: null,
  user: null,
  availableOrganisations: [],
  isAuthenticated: false,
  activeOrganisation: null,
  storageVersion: 2,
  darkMode: false
})

export const mutations = {
  setDarkMode(context, data) {
    context.darkMode = data
  },
  setChallenge(state, payload) {
    state.challenge = payload
  },
  setActiveOrganisationId(state, organisationId) {
    state.activeOrganisationId = organisationId
  },
  setUser(state, payload) {
    state.user = payload
  },
  setAvailableOrganisations(state, payload) {
    state.availableOrganisations = payload
  },
  setAuthenticationState(state, value) {
    state.isAuthenticated = value
  },
  setActiveOrganisation(state, organisation) {
    state.activeOrganisation = organisation
  },
  reset(state) {
    state.challenge = false
    state.activeOrganisationId = null
    state.user = null
    state.availableOrganisations = []
    state.isAuthenticated = false
    state.activeOrganisation = null
  }
}

export const getters = {

  firstOrganisationLanguage(state) {
    return state.activeOrganisation.languages[0];
  },

  organisationColor(state) {
    const hex8 = state.activeOrganisation.colorHex || '#333'
    if (hex8.length !== 9 || hex8[0] !== '#') {
      return hex8
    }
    return hex8.substring(0, 7);
  },

  accessToken(state) {
    if (!state.isAuthenticated || !state.activeOrganisationId || state.availableOrganisations.length == 0) {
      return;
    }

    const org = state.availableOrganisations.find(v => v.organisation.id === state.activeOrganisationId);

    // unable to find the given org in the orgs list
    if (!org) {
      return
    }

    return org.access_token;
  },

  wssToken(state) {
    if (!state.isAuthenticated || !state.activeOrganisationId || state.availableOrganisations.length == 0) {
      return;
    }

    const org = state.availableOrganisations.find(v => v.organisation.id === state.activeOrganisationId);

    // unable to find the given org in the orgs list
    if (!org) {
      return
    }

    return org.wss_token;
  },


  isAuthenticatedButNoActiveOrganisation(state) {
    return state.isAuthenticated && state.user && state.availableOrganisations.length > 0 && !state.activeOrganisation
  },

  isAuthenticatedOrganisationSession(state) {
    return state.isAuthenticated && state.activeOrganisation && state.user && state.availableOrganisations.length > 0
  },

  appLanguage(state) {
    if (!state.isAuthenticated || !state.user) {
      return 'de';
    }

    return state.user.hasOwnProperty('app_language') ? state.user.app_language : 'de'
  }
}

export const actions = {
  async ensureSession (context) {
    try {
      const response = await this.$axios.$post('me/keys', {
        challenge: context.state.challenge,
        useragent: window.navigator.userAgent
      })

      await context.commit('setUser', response.user)
      await context.commit('setAvailableOrganisations', response.organisations)
      await context.commit('setAuthenticationState', true);
    } catch(error) {
      await context.dispatch('destroyAndReset')
    }
  },

  async ensureChallenge (context) {
    try {
      const response = await this.$axios.$post('auth/keys', {
        challenge: context.state.challenge,
        useragent: window.navigator.userAgent
      })

      await context.commit('setUser', response.user)
      await context.commit('setAvailableOrganisations', response.organisations)
      await context.commit('setAuthenticationState', true);
    } catch(error) {
      await context.dispatch('destroyAndReset')
    }
  },

  async ensureActiveOrganisation (context) {
    try {
      const response = await this.$axios.get('organisation')
      await context.commit('setActiveOrganisation', response.data)
      let timezone = "Europe/Zurich" // testing: America/New_York
      if (response.data.timezone && response.data.timezone.length > 0) {
        timezone = response.data.timezone
      }
      this.$moment.tz.setDefault(timezone);
    } catch(error) {
      await context.dispatch('destroyAndReset')
    }
  },

  async destroyAndReset (context) {
    try {
      await this.$axios.post('auth/destroy', {
        challenge: context.state.challenge
      })
    } catch (e) {

    }
    context.commit('reset');
  }
}
