
import OpenAi from './OpenAi.vue'
export default {
  components: { OpenAi },
  data:() => ({
    dialog: false,
    value: '',
  }),
  methods: {
    getNow() {
      const obj = window.getSelection()
      this.value = obj.toString()
      this.dialog = true
    }
  }
}
