/**
 * Transforms Yii2 422 Errors in Objects
 * @param response
 * @returns Object
 */
export function errorsArrayToObject (response) {
  if (Array.isArray(response)) {
    return response.reduce((result, item) => {
      if (!result[item.field]) {
          result[item.field] = []
      }
      result[item.field].push(item.message)
      return result
    }, {})
  }
  return {}
}

export function transformError(error) {
  if (error && error.response && error.response.status == 422) {
    const response = errorsArrayToObject(error.response.data);
    return response;
  }
}

export function slugify(text, replaceChar, camelCase) {

  if (!text || text.length == 0) {
    return text
  }

  if (!replaceChar) {
    replaceChar = '-'
  } else if (replaceChar == 'space') {
    replaceChar = ''
  }

  let txt = text.toString()

  if (!camelCase) {
    txt = txt.toLowerCase()
  }

  var pattern = /[\W_]+/g;

  return txt
    .replace(/&/g, replaceChar + 'and' + replaceChar)
    .replace(/\u00e4/g, "ae")
    .replace(/\u00fc/g, "ue")
    .replace(/\u00f6/g, "oe")
    .replace(pattern, replaceChar);
}

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
