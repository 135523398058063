export const state = () => ({
  nitroLeftSidePercentage: 60,
  nitroLiveEditActiveUrls: {}
})

export const getters = {
  getNitroLiveEditActiveUrl: (state) => (integrationId) => {
    return Object.hasOwn(state.nitroLiveEditActiveUrls, integrationId) ? state.nitroLiveEditActiveUrls[integrationId] : false
  },
}

export const mutations = {
  setNitroLeftSidePercentage(state, value) {
    state.nitroLeftSidePercentage = parseInt(value)
  },
  setNitroLiveEditActiveUrl(state, value) {
    state.nitroLiveEditActiveUrls[value.integrationId] = value.url
  }
}
