export default async function (context) {

  if (context.store.getters['authStorage/isAuthenticatedButNoActiveOrganisation']) {
    return context.redirect({
      name: 'auth-switcher'
    })
  }
  else if (!context.store.getters['authStorage/isAuthenticatedOrganisationSession']) {
    context.store.commit('me/SET_REDIRECT', context.route.fullPath)
    return context.redirect({
      name: 'auth-login'
    })
  }
}