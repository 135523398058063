import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'


Vue.use(VueApexCharts)
Vue.component('Apexchart', VueApexCharts)

export default async () => {
  // https://github.com/stevenho0811/nuxt-breakpoints/blob/master/lib/plugin.js
  const needPolyfill = !Object.prototype.hasOwnProperty.call(window, 'ResizeObserver')

  if (needPolyfill) {
    const ResizeObserver = await import('resize-observer-polyfill')

    Object.defineProperty(window, 'ResizeObserver', {
      value: ResizeObserver.default,
      writable: false
    })
  }
}