export default (context, inject) => {

  // add axios interceptors
  context.$axios.interceptors.request.use((config) => {
    const token = context.store.getters['authStorage/accessToken']
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  })

  // redirect to login page if 401
  context.$axios.onResponseError(e => {
    if ((e.response && e.response?.status === 422) && context.store.getters['authStorage/isAuthenticatedOrganisationSession']) {
      context.store.dispatch('snackbar/errorResponse', e)
    } else if (e.response && e.response?.status === 401) {
      context.redirect({
        name: 'auth-login'
      })
    }
  })

  // inject $auth
  inject('auth', {
    get user() {
      return context.store.state.authStorage.user
    },
    get activeOrganisation() {
      return context.store.state.authStorage.activeOrganisation
    },
    get activeOrganisationColor() {
      return context.store.getters['authStorage/organisationColor']
    },
    get accessToken() {
      return context.store.getters['authStorage/accessToken']
    },
    get wssToken() {
      return context.store.getters['authStorage/wssToken']
    }
  })
}
