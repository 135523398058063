import { uuidv4 } from "../helpers/forms";

export const state = () => ({
  items: [],
});

export const mutations = {
  newItem(context, object) {
    context.items.push(object)
  },
  cleanup(context, value) {
    let items = []
    value.forEach(item => {
      const index = context.items.findIndex(row => row.id == item.id)
      if (index > 0) {
        items.push(item)
      }
    })
    context.items = items
  }
}

export const actions = {
  success(context, value) {
    context.commit('newItem', {
      id: uuidv4(),
      message:value,
      color:"green",
      timeout:7000
    })
  },
  error(context, value) {
    context.commit('newItem', {
      id: uuidv4(),
      message:value,
      color:"red",
      timeout:7000
    })
  },
  errorResponse(context, error) {
    if (error && error.response && error.response.status == 422) {
      if (Array.isArray(error.response.data)) {
        error.response.data.forEach(item => {
          context.dispatch('error', item.message)
        })
      }
    }
  }
}
