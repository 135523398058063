
export default {
  data:() => ({
    isLoadingYes: false,
    isLoadingNo: false
  }),
  methods: {
    async yes() {
      this.isLoadingYes = true
      await this.$axios.post('me/data', {is_newsletter_subscriber: 1})
      await this.hide()
    },
    async no() {
      this.isLoadingNo = true
      await this.$axios.post('me/data', {is_newsletter_subscriber: 0})
      await this.hide()
    },
    async hide() {
      await this.$axios.put('me/settings', {show_newsletter_overlay: 0})
      await this.$store.dispatch('me/fetch')
      this.isLoadingYes = false
      this.isLoadingNo = false
    }
  }
}
