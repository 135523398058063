import de from '~/locales/de'
import en from '~/locales/en'

export default {
  lang: {
    locales: {de, en},
    current: 'de'
  },
  theme: {
    dark: false, // dark mode
    themes: {
      light: {
        primary: '#455A64', // BADGES
        secondary: '#37474F', // TOOLBARS (TABS)
        drawer: '#404040',
        drawerlink: '#F2F2F2',
        skyblue: '#2cc1ff'
      },
      dark: {
        primary: '#2cc1ff',
        secondary: '#414A4C', // TOOLBARS (TABS)
        drawer: '#E5E4E2',
        drawerlink: '#E5E4E2',
        skyblue: '#0093d6'
      }
    }
  }
}
