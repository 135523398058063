export const state = () => ({
  recentItems: [],
  storageVersion: 1
});


export const actions = {
  clearRecents (context) {
    context.commit('recentClear');
  },
}

export const mutations = {
  recentItem(context, value) {
    value.time = new Date()
    context.recentItems.forEach((item,index) => {
      if (item.id == value.id && item.type == value.type) {
        context.recentItems.splice(index, 1)
      }
    })
    context.recentItems = context.recentItems.slice(0, 45)
    context.recentItems.unshift(value)
  },

  recentClear(context) {
    context.recentItems = [];
  },
  /**
   *
   * @param {*} context
   * @param {id:0,type:poi} value
   */
  recentRemove(context, value) {
    context.recentItems.forEach((item,index) => {
      if (item.id == value.id && item.type == value.type) {
        context.recentItems.splice(index, 1)
      }
    })
  }
}
  