export const state = () => ({
  data: [],
});

export const mutations = {
  SET_DATA(context, data) {
    context.data = data;
  }
}

export const actions = {
  async fetch (context) {
    const response = await this.$axios('rbac/roles?expand=permissions');
    context.commit('SET_DATA', response.data);
  }
}
