
import InfoIconModal from '../components/views/InfoIconModal.vue'
import LoginLayoutFooter from '../components/layouts/LoginLayoutFooter.vue'
export default {
  components: { InfoIconModal,LoginLayoutFooter },
  data:() => ({
    showContainer: false,
  }),
  head() {
    return {
      title: this.title
    }
  },
  computed: {
    title() {
      const last = this.$store.state.hub.breadcrumbData[this.$store.state.hub.breadcrumbData.length - 1]
      return last ? last.text : ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.showContainer = true
    }, 250)
  }
}
