export const state = () => ({
  hubLinkPages: []
})

export const mutations = {
  setHubLinkPages(state, pages) {
    state.hubLinkPages = pages
  }
}

export const getters = {

  flatLinkPagesArrayByLanguageShortcode: (state) => (languageShortcode) => {
    const pages = []

    const pageIterator = (array, parent, prefix) => {
      array.forEach(page => {
        const text = parent ? parent + ' ➔ ' + page.title : page.title
        pages.push({ value: page.uid, text: prefix + text })

        if (page.children && page.children.length > 0) {
          pageIterator(page.children, text, prefix)
        }
      })
    }

    const languagesContext = state.hubLinkPages.languages || []
    const containers = state.hubLinkPages.containers || []
    containers.forEach(container => {
      let languageConfig = container.language_config || []
      languageConfig.forEach(lang => {
        let lngContext = languagesContext.find(l => l.uid === lang.uid)
        if (!lngContext || lngContext.data.shortcode !== languageShortcode) {
          return
        }
        pageIterator(lang.pages, false, container.label + ': ')
      })
    })

    return pages
  },

  flatLinkPagesArray: (state) => {
    const pages = []

    const pageIterator = (array, parent, prefix) => {
      array.forEach(page => {
        const text = parent ? parent + ' ➔ ' + page.title : page.title
        pages.push({ value: page.uid, text: prefix + text })

        if (page.children && page.children.length > 0) {
          pageIterator(page.children, text, prefix)
        }
      })
    }

    const languagesContext = state.hubLinkPages.languages || []

    const statePages = state.hubLinkPages.default || []
    pageIterator(statePages, false, '')

    const containers = state.hubLinkPages.containers || []
    containers.forEach(container => {
      pageIterator(container.pages, false, container.label + ': ')

      let languageConfig = container.language_config || []
      languageConfig.forEach(lang => {
        let prefiox = ''
        let lngContext = languagesContext.find(l => l.uid === lang.uid)
        if (lngContext) {
          prefiox = '(' + lngContext.data.name + ') '
        }
        pageIterator(lang.pages, false, prefiox + container.label + ': ')
      })
    })

    return pages
  },

  findLinkArrayByUid: (state) => (findUid) => {
    const pages = []

    const pageIterator = (array, findUid) => {
      let tmpPages = []
      array.forEach(page => {
        if (page.uid == findUid) {
          tmpPages.push(page)
        } else if (page.children && page.children.length > 0) {
          let foundItems = pageIterator(page.children, findUid)

          if (foundItems.length > 0) {
            foundItems.forEach(item => {
              tmpPages.push(item)
            })
            tmpPages.push(page)
          }
        }
      })

      return tmpPages
    }

    const languagesContext = state.hubLinkPages.languages || []

    const statePages = state.hubLinkPages.default || []
    pages.push(...pageIterator(statePages, findUid))

    const containers = state.hubLinkPages.containers || []
    containers.forEach(container => {
      pages.push(...pageIterator(container.pages, findUid))

      let languageConfig = container.language_config || []
      languageConfig.forEach(lang => {
        let lngs = pageIterator(lang.pages, findUid)
        if (lngs.length > 0) {
          let lngContext = languagesContext.find(l => l.uid === lang.uid)
          if (lngContext) {
            lngs.push({slug: lngContext.data.shortcode})
          }
        }
        pages.push(...lngs)
      })
    })

    return pages
  }
}
